import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn, TableComponent } from '../../table.component';

@Component({
  selector: 'filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialog {
  public loader = false;
  public mode = null;
  protected save_clicked = false;
  public tableColumns = [];
  public filter_db_fields_values = {};
  public currentColVisibility = [];
  public tableComponent: TableComponent = null;
  public isMobile = null;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<FiltersDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {

    if(data.tableComponent) {
      this.tableComponent = data.tableComponent;
    }
    
    if(data.tableColumns) {
      this.tableColumns = data.tableColumns;
    }

    if(data.currentColVisibility) {
      this.currentColVisibility = data.currentColVisibility;
    }

    if(data.filter_db_fields_values) {
      this.filter_db_fields_values = data.filter_db_fields_values;
    }

    this.isMobile = data.isMobile;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  filter() {
    this.save_clicked = true;
    this.dialogRef.close('bt_filter');
  }

  applyColFilter(filterValue: string, column: TableColumn, event = null) {
    if(!this.tableComponent)
    return null;

    if(this.tableComponent.responsive && this.isMobile && !this.tableComponent.mobilePagination) { // only for mobile
      this.tableComponent.scrollPage = 1;
      this.tableComponent.currentData = [];
    }
    this.tableComponent.applyColFilter(filterValue, column, event);
  }

  selectionChange(filterValue: string, column: TableColumn, event = null) {
    if(!this.tableComponent)
    return null;
    this.tableComponent.selectionChange(filterValue, column, event)
  }

  onResetFilters() {
    if(!this.tableComponent)
    return null;
    this.tableComponent.onResetFilters();
  }

  // getColumnPropertyValue(column: TableColumn, property: string) {
  //   return this.tableComponent.getColumnPropertyValue(column, property);
  // }
}
