import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class PwaService {
  public promptEvent = null;
  public newVersionAvailable = false;

  constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef) {
    if (!environment.enable_pwa) {
      return ;
    }
    console.log('PwaService.');
    window.addEventListener('beforeinstallprompt', (event) => {
      this.promptEvent = event;
    });

    this.initCheckUpdates();
  }

  install() {
    this.promptEvent.prompt();
    this.promptEvent.userChoice.then((response) => {
      this.promptEvent = null;
      if (response.outcome === 'accepted') {
        console.log('yes, please install');
      } else {
        console.log('No thanks, I am good!');
      }
    });
  }

  doNotInstall() {
    this.promptEvent = null;
  }

  initCheckUpdates() {
    console.log('AppUpdateService');
    // this.swUpdate.available.subscribe((event) => {
    this.swUpdate.versionUpdates.subscribe((event) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${event.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${event.currentVersion.hash}`);
          console.log(`New app version ready for use: ${event.latestVersion.hash}`);
          this.newVersionAvailable = true;
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${event.version.hash}': ${event.error}`);
          break;
        }
      // console.log('current version is', event.current);
      // console.log('available version is', event.available);
    });

    // resolves to true if an update was activated successfully
    // esolves to false if no update was available 
    // this.swUpdate.activated.subscribe((event) => {
    this.swUpdate.activateUpdate().then((event) => {
      console.log('update was activated successfully', event);
      // console.log('old version was', event.previous);
      // console.log('new version is', event.current);
    });

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    everyHourOnceAppIsStable$.subscribe(() => {
      console.log('checkForUpdate');
      try {
        this.swUpdate.checkForUpdate();
      } catch(e) {
        console.log('Service worker disabled');
      }
    });
  }
}
